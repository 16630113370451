.order_details_container {
	display: flex;
	gap: 24px;
	font-size: 12px;
	line-height: 19.2px;
	flex-direction: column;
	padding: 0px 12px 24px 12px;

	.order_id,
	.order_item,
	.order_sample_collection,
	.order_confirmation {
		display: flex;
		gap: 8px;

		div {
			color: var(--ltGreyscale900);
			font-size: 14px;
			font-weight: 500;
			line-height: 160%; /* 25.6px */
		}

		p {
			color: var(--ltGreyscale600);
			font-size: 14px;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
		}
	}
}

.button_container {
	background-color: #ffffff;
	display: flex;
	gap: 1rem;
	width: 100%;
	padding: 24px 24px 24px 24px;
	position: sticky;
	bottom: 0;
	border-top: 1px solid var(--ltGreyscale300);
}
